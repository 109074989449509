import { template } from "@ember/template-compiler";
import Information from './information';
import LanguageAvailability from './language-availability';
import Survey from './survey';
export default template(`
  <div class="top-banners">
    <Information />
    <LanguageAvailability />
    <Survey />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
